body {
  font-family: Open Sans,Arial,sans-serif;
  color: #666;
  background-color: #fff;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.8em;
  font-size: 16px;
}


.btn {
  border-radius: 0;
  border: none;
  color: #000;
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.btn-primary {
  background-color: #967126;
  color: white
}
.btn-primary:hover,.btn-primary:focus {
  background-color: #e1a600;;
  border-color: rgb(238, 238, 238);
}
.btn-primary.disabled {
  background-color: #967126;
  color: #ccc;
}


.btn-secondary {
  background-color: #006138;
  color: #fff;
}
.btn-secondary:hover,.btn-secondary:focus {
  background-color: #054d2d;;
  border-color: rgb(238, 238, 238);
}
.btn-secondary.disabled {
  background-color: #006138;
  color: #ccc;
}

.Toastify {
  line-height: 1.5em;
}

.card {
  border-radius: 10px;
}
.card.card-white {
  border: none;
  box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 30%);
}
.card.card-white .card-header {
  border-bottom: 1px solid #cdcdcd;
  background-color: #fff;
  padding-left:0;

}
.card-header-large {
  font-size: 2rem;
  color: #000;
  font-weight: 400;
}

.chart-progress {
  height: 40px;
}

.page-header {
  border-bottom: 1px solid #eee;
  color: #333;
}



@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateX(0);
  }

  20% {
    -webkit-transform: translateX(-5px);
  }

  40% {
    -webkit-transform: translateX(5px);
  }

  60% {
    -webkit-transform: translateX(-5px);
  }

  80% {
    -webkit-transform: translateX(5px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-5px);
  }

  40% {
    transform: translateX(5px);
  }

  60% {
    transform: translateX(-5px);
  }

  80% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0);
  }
}

.shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: 1;
}


.btn.btn-info {
  background-color: #0169a6;
  color: #fff;
}

.btn.btn-info-outline {
  color: #0169a6;
  border-color: #0169a6;
  border-width: 2px;
  border-style: solid;
  border-radius: 3px;
}

.btn.btn-info-outline:disabled {
  background-color: #cdcdcd;
  border-color: #cccccc;
}

.row.input-row {
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
}
.row.input-row > [class^="col"],.row.input-row > [class*=" col"] {
  border: 1px solid rgb(238, 238, 238);
  margin-left: -1px;
  margin-right: -1px;
}

.input-row .invalid-feedback {
  clear: both;
}

.form-control {
  border-radius: 0;
}

.form-control.error:not(:focus) {
  border-color: #ff6464;
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: 1;
}
.invalid-tooltip {
  opacity: 0.7;
}


h5 {
  color: #666;
}
h6 {
  color: #666;
  font-weight: 800;
}
.img-circle {
  border-radius: 100%;
  object-fit:cover;
}

.img-50 {
  width: 50px;
  height: 50px;
}

.img-30 {
  width: 30px;
  height: 30px;
}

.profile-picture-border {
  border: 1px solid #ccc;
}

.profile-picture-full {
  width: 400px;
  height: 400px;
}

@media (max-width: 500px) {
  .profile-picture-full {
    width: 75vw;
    height: 75vw;
  }
}

body.modal-open {
  overflow: hidden;
  /*padding-right: 0 !important;*/
}


.font-bold {
  font-weight: 800;
}

.bg-red {
  background-color: red;
}
.bg-orange {
  background-color: orange;
}
.bg-blue {
  background-color: blue;
}
.bg-purple {
  background-color: purple;
}
.bg-green {
  background-color: green;
}
.bg-gray {
  background-color: gray;
}
.bg-primary {
  background-color: #967126;
}
.bg-blue2 {
  background-color: #0169a6;
}
.bg-green2 {
  background-color: #006138;
}
.bg-green3 {
  background-color: #42991a;
}
:root {
  --bs-primary: #967126;
  /*--bs-primary-rgb: 248, 195, 1;*/
  --bs-primary-rgb: 150, 113, 38;
}
.text-red {
  color: red;
}
.text-orange {
  color: orange;
}
.text-blue {
  color: blue;
}
.text-purple {
  color: purple;
}
.text-green {
  color: green;
}
.text-gray {
  color: gray;
}
.text-black {
  color: black;
}

.text-blue2 {
  color:#0169a6
}

.badge {
  border-radius: 0;
}

.activegroup-header {
  background-color: #002463;
  color: #fff;

  border-bottom: solid;
  border-bottom-width: 10px;
  border-bottom-color: #967126;
  box-shadow: 0 12px 18px -6px rgba(0,0,0,0.3);
}

.activegroup-footer {
  background-color: #002463;
  color: #fff;
}

.activegroup-header .btn-close {
  filter: brightness(0) invert(1);
}

.modal-content {
  border-radius: 0;
}
.modal-header {
  border-radius: 0;
}

.lineheight-1 {
  line-height: 1em;
}
.lineheight-15 {
  line-height: 1.5em;
}
.pointer-cursor {
  cursor: pointer;
}
.pointer-copy {
  cursor: copy;
}
.pointer-move {
  cursor: move;
}


.copy-links-icon {
  width: 40px;
}

.info-card-value {
  font-weight: 800;
  font-size: 42px;
}

.btn-link {
  padding:0;
}
.btn-text {
  padding:0;
  color: #0169a6;
}


.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.accordion-button.collapsed {
  background-color: #f4f4f4;
}

.img-white-border {
  border: white 5px solid;
  box-shadow: 0px 12px 18px -6px rgba(0,0,0,0.3)
}

.min-vh-50 {
  min-height: 50vh;
}

.min-vw-50 {
  min-width: 50vw;
}

@media (max-width: 991.98px) {
  /*side nav bar*/
  .sidebar {
    height: 100%; /* 100% Full-height */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    width: 0;
    opacity: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 120px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    background-color: white;
  }

  .sidebar.sidebar-open {
    width: 100%; /* 0 width - change this with JavaScript */
    padding-left: 2em;
    padding-right: 2em;
    opacity: 100%;
  }
}


.new-item {

  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: bg-fade 1s;

  /* When the animation is finished, start again */
  animation-iteration-count: 1;
}

@-webkit-keyframes bg-fade {
  0% {
    background-color: #ffffff;
  }

  50% {
    background-color: #666666;
  }

  100% {
    background-color: #ffffff;
  }
}

@keyframes bg-fade {
  0% {
    background-color: #ffffff;
  }

  50% {
    background-color: #666666;
  }

  100% {
    background-color: #ffffff;
  }
}


.main-content-container {
  min-height: calc(100vh - (135px + 3rem));
/*  3rem is main content margin-top, 135px = 90px header + 45px footer*/
}

.input-sm {
  font-size: 0.875rem;
}


/* MEDIA GALLERY */
.media-gallery-item {
  min-height: 260px;
}

.media-gallery-item .img {
  opacity: 1;
  -o-transition: opacity .2s ease-in-out;
  -ms-transition: opacity .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out;
  -webkit-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out;
}
.media-gallery-item:hover .img {
  opacity: 0.1;
}
.media-gallery-item .actions {
  display: none;
}
.media-gallery-item:hover .actions {
  display: block;
}
/* END OF MEDIA GALLERY*/



.text-shadow {
  text-shadow: #ccc 1px 1px 5px;
}

.strikethrough {
  text-decoration: line-through;
}



.bg-light-gray {
  background-color: #cdcdcd;
  /*color: #666666;*/
}
.bg-darkseagreen {
    background-color: DARKSEAGREEN;
}
.bg-plum {
    background-color: PLUM;
}
.bg-rosybrown {
    background-color: ROSYBROWN;
}
.bg-peru {
    background-color: PERU;
}
.bg-burlywood {
    background-color: BURLYWOOD;
}
.bg-darkkhaki {
    background-color: DARKKHAKI;
}


.lead-main-info-container {
  border: 1px solid #cdcdcd;
  border-left: 10px solid #0169a6;
  border-radius: 10px;
}
.lead-main-info-color {
  color: #0169a6
}

.lead-broker-info-container {
    border-left: 10px solid #967126;
    background-color: #0169a6;
    border-radius: 10px;
}

.no-wrap {
  white-space: nowrap;
}