.public-page.container {
    background-color: #002463;
    color: #fff;
    /*margin-top:30px;*/
    /*margin-bottom: 30px;*/
    border-radius: 5px;
    /*width: 80%;*/
    /*min-height: calc(100vh - 60px);*/
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 2px 18px 0px rgb(0 0 0 / 30%);
}

@media (min-width: 768px) {
    .public-page.container {
        width: 80%;
        max-width: 900px;
    }
}

.login-page .registration-col {
    background-color: #0169a6;
    box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 30%);
    border-radius: 5px;
}

.login-page .login-col {
    background-color: #967126;
    box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 30%);
    border-radius: 5px;
    color: white;
}

.login-page .login-col label {
    font-weight: bold;
}

.login-page .form-control {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    border-style: none;
    border-bottom-style: solid;
    color: #fff;
}
.login-page .form-control:active, .login-page .form-control:focus {
    color: #fff;
}
.login-page .form-control::placeholder {
    color: #ccc;
}

.login-page .login-col .btn.btn-primary {
    background-color: #0169a6;
    color: white;
}

.login-page .registration-description {
    font-size: 0.9rem;
}

.public-page small {
    font-size: 0.75rem;
}

.public-page .btn {
    border-radius: 5px;
    width: 100%;
    color: #000;
    font-weight: 600;
}

.public-page .form-control {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    border-style: none;
    border-bottom-style: solid;
    /*color: #fff;*/
}

.login-page .form-control::placeholder {
    /*color: #ccc;*/
}

.public-page .form-floating>label {
    /*color: #ccc;*/
}


.login-page .forgot-password-trigger {
    cursor: pointer;
}
.login-page .forgot-password .form-control{
    color: #000;
}