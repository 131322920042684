.navbar {
    padding: 0;
}
.dashboard-header .container-fluid {
}

.dashboard-header .navbar-brand {
    height: 80px;
}
.dashboard-header .navbar-brand img {
    padding: 10px 36px;
    max-height: 100%;
    /*max-height: 50px;*/
}

@media (max-width: 465px) {

    .dashboard-header .navbar-brand {
        height: 60px;
    }
    .dashboard-header .navbar-brand img {
        padding: 10px 10px;
    }
}

@media (max-width: 335px) {

    .dashboard-header .navbar-brand {
        display: none;
    }
    .dashboard-header .navbar-brand img {
        padding: 5px;
    }
}

.dashboard-header .img-fluid {
    max-height: 100%;
}

.dashboard-header .navbar-collapse .col {
    border: solid red 2px;
}
.dropdown .toggle-clear {
    background-color: rgba(0, 0, 0, 0);
    font-weight: 400;
}

.dropdown .toggle-clear:focus {
    box-shadow: none;
}
.dashboard-header .dropdown .profile-toggle {

    color: #fff;
}
.dashboard-header .dropdown .profile-toggle img {
    width: 50px;
    height: 50px;
}

.sidebar >  .row > .col {
    padding-left: 0;
}
.greeting-content {
    border-bottom: 5px solid #0169a6;
}

.sidebar .sidebar-nav {
    border-bottom: 1px solid #967126;
    padding-top: 15px;
    padding-bottom: 15px;
}

.sidebar .sidebar-nav .fa-layers {
    padding-right: 4rem;
}

.sidebar .sidebar-nav a{
    color: #666;
    text-decoration: none;
}

.sidebar .sidebar-nav a.active{
    font-weight: 800;
    color: #0169a6;
}
.sidebar .sidebar-nav svg{
    padding-right: 1rem;
    width: 3rem;
}
.sidebar .sidebar-nav svg:not(.fa-inverse) {
    color: #0169a6;
}

.sidebar .sidebar-nav a.active svg:not(.fa-inverse) {
    color: #967126;
}
